































import Vue from 'vue'
import CreditLine, {
  CreditLineState
} from '../../../../store/models/CreditLine'

export default Vue.extend({
  data() {
    return {
      columns: [
        { label: 'Nom', field: 'name', searchable: true },
        { label: 'Référence', field: 'reference' },
        { label: 'Montant MTN', field: 'mtnProvisionAmount', currency: true },
        { label: 'Montant MOOV', field: 'moovProvisionAmount', currency: true },
        {
          label: 'Montant Total',
          field: 'totalProvisionAmount',
          currency: true
        },
        { label: 'Crée Par', field: 'createdBy.fullname' },
        { label: 'Validé Par', field: 'validatedBy.fullname' },
        { label: 'Statut', field: 'state' },
        { label: '', field: '_actions', align: 'center', sortable: false }
      ],
      states: {
        [CreditLineState.CREATED]: { color: 'blue', text: 'À valider' },
        [CreditLineState.VALIDATED]: { color: 'green', text: 'Validé' },
        [CreditLineState.COMPLETED]: { color: 'green', text: 'Effectué' },
        [CreditLineState.COMPLETED_WITH_FAILURE]: {
          color: 'red',
          text: 'Terminé avec erreur'
        }
      }
    }
  },

  computed: {
    items() {
      return CreditLine.query()
        .with(['createdBy', 'validatedBy', 'provisions'])
        .orderBy('createdAt', 'desc')
        .where('state', (state: string) => state !== 'canceled')
        .all()
    }
  },

  created() {
    CreditLine.api().fetchAll()
  }
})
